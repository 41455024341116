import React from "react"

import {
  GatsbyImage,
  getImage,
  withArtDirection,
  GatsbyImageProps,
  ImageDataLike,
} from "gatsby-plugin-image"

interface ImgProps extends Omit<GatsbyImageProps, "image"> {
  large: ImageDataLike
  mob: ImageDataLike
}

export function Img({ large, mob, className, alt }: ImgProps) {
  const images = withArtDirection(getImage(large)!, [
    {
      media: "(max-width: 599.95px)",
      image: getImage(mob)!,
    },
  ])

  return <GatsbyImage className={className} image={images} alt={alt} />
}
