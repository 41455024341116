import React from "react"

export default function StarImg({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#E8E8E8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.3332 6.05833C17.2806 5.90591 17.1849 5.77202 17.0577 5.67292C16.9305 5.57381 16.7772 5.51375 16.6166 5.5L11.8749 4.80833L9.7499 0.499999C9.68166 0.359106 9.57512 0.240284 9.44247 0.157143C9.30983 0.074003 9.15645 0.0299072 8.9999 0.0299072C8.84335 0.0299072 8.68997 0.074003 8.55732 0.157143C8.42468 0.240284 8.31814 0.359106 8.2499 0.499999L6.1249 4.8L1.38323 5.5C1.229 5.52192 1.084 5.58663 0.964692 5.6868C0.84538 5.78696 0.756533 5.91856 0.708233 6.06667C0.664021 6.21139 0.660053 6.36543 0.696756 6.51224C0.733459 6.65906 0.809448 6.7931 0.916566 6.9L4.35823 10.2333L3.5249 14.9667C3.49126 15.1237 3.50369 15.2871 3.56072 15.4372C3.61775 15.5873 3.71693 15.7178 3.84634 15.8128C3.97575 15.9079 4.12988 15.9636 4.29017 15.9731C4.45047 15.9827 4.61012 15.9457 4.7499 15.8667L8.9999 13.6417L13.2499 15.8667C13.3669 15.9326 13.4989 15.9671 13.6332 15.9667C13.8098 15.9673 13.9819 15.9119 14.1249 15.8083C14.2542 15.7157 14.3543 15.588 14.4133 15.4404C14.4724 15.2927 14.488 15.1312 14.4582 14.975L13.6249 10.2417L17.0666 6.90833C17.1869 6.8064 17.2758 6.67242 17.323 6.52196C17.3702 6.37149 17.3738 6.21073 17.3332 6.05833Z" />
    </svg>
  )
}
