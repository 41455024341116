import React from "react"
import StarImg from "../StarImg"

import * as styles from "./card.module.scss"

type Props = {
  userImg: string
  rate: number
  name: string
  review: string
  logoCompany: string
}

export const Card: React.FC<Props> = ({ userImg, rate, name, review, logoCompany }) => {
  const createRating = () => {
    let cardRate = rate
    const starArr = []
    const arr = Array.from(Array(5).keys())
    for (let i = 0; i < arr.length; i += 1) {
      cardRate -= 1
      if (cardRate >= 0) {
        starArr.push(<StarImg key={i} className={styles.filled} />)
      } else {
        starArr.push(<StarImg key={i} />)
      }
    }
    return starArr
  }

  return (
    <div className={styles.container}>
      <img width="72" height="72" loading="lazy" src={userImg} alt="avatar" />
      <span className={styles.name}>{name}</span>
      <div className={styles.rate}>{createRating()}</div>
      <p className={styles.review}>{review}</p>
      <img
        width="110"
        height="24"
        loading="lazy"
        src={logoCompany}
        alt="logo"
        className={styles.img_logo}
      />
    </div>
  )
}
