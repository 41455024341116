// extracted by mini-css-extract-plugin
export var blackFridayHalva = "banner-module--blackFridayHalva--2HgeY";
export var blackFridayPKW = "banner-module--blackFridayPKW--YOvKV";
export var btn = "banner-module--btn--fg6oE";
export var btnBlock = "banner-module--btnBlock--FudND";
export var container = "banner-module--container--Hqxsk";
export var hideMobBlock = "banner-module--hideMobBlock--6jLpG";
export var img = "banner-module--img--3ozEV";
export var mobBtn = "banner-module--mobBtn--VgfKE";
export var pkwBonus = "banner-module--pkwBonus--p7z0j";
export var secondContainer = "banner-module--secondContainer--q7gJJ";
export var subTextFriday = "banner-module--subTextFriday--J+gbq";
export var text = "banner-module--text--tCbbo";
export var vkEducation = "banner-module--vkEducation--7CMxG";