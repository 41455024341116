import { AllDynamicBanner, DynamicBanner } from "../interfaces/pageProps"

export function getDynamicBannerUrl(data: AllDynamicBanner, url: string) {
  let dymanicBannerDesk = null
  let dymanicBannerMob = null

  if (url) {
    data?.edges?.forEach((item: DynamicBanner) => {
      if (item.node.name === url) {
        dymanicBannerDesk = item.node.deskGatsbyImg.childImageSharp
        dymanicBannerMob = item.node.mobGatsbyImg.childImageSharp
      }
    })
    return {
      dymanicBannerMob,
      dymanicBannerDesk,
    }
  }

  return null
}
