import React from "react"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import { DLClickEvent } from "../../helpers/WatcherDL/DLEvents"
import scrollToPersonalForm from "../../helpers/scrollToPersonalForm"
import * as styles from "./topCta.module.scss"

import { GiftImage } from "./img/Gift"
import { CountDown } from "../Headers/NewHeader/RightSection/CountDown"
import { GiftIcon } from "./img/GiftIcon"

type TopCtaProps = {
  orderNum?: string
  countDownTime?: number
}

export default function TopCta({ orderNum, countDownTime }: TopCtaProps) {
  const isMobile = useMediaQuery("(max-width:599.5px)")

  const handleClickBlock = () => {
    DLClickEvent({ name: "Халва.Десятка в подарок", placement: "top_button_halva" })

    if (isMobile) {
      scrollToPersonalForm()
    }
  }

  const handleClickBtn = () => scrollToPersonalForm()

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <section
        className={styles.section}
        id="topCta"
        onClick={handleClickBlock}
        onKeyDown={() => {}}
        data-exclude={orderNum}
      >
        <Container>
          <div className={styles.block}>
            <GiftImage alt="gift" className={styles.img} />
            <p className={styles.text}>
              Оформите Халву и получите подписку
              <br /> <span className={styles.textBold}>«Халва.Десятка» в подарок</span>
            </p>
            <div className={styles.wrapButtonAndCountDown}>
              <Button className={styles.button} onClick={handleClickBtn}>
                Получить подарок
              </Button>
              <div className={styles.wrapCountdown}>
                <GiftIcon />
                <div>
                  <CountDown countDownTime={countDownTime} />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}
