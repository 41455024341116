import React, { useRef, useState, useEffect, useCallback } from "react"
import Slider from "react-slick"
import Container from "@ecom/ui/components/Container"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { IconButton } from "@material-ui/core"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import clsx from "clsx"
import { Card } from "./Card"
import * as styles from "./reviews.module.scss"
import { Review, settings, fetchReviews } from "./helpers"

type Props = {
  page?: "default" | "debet"
  orderNum?: string
  additionalEventInDataLayer?: boolean
}

export function Reviews({ orderNum, page = "default" }: Props) {
  const sliderRef = useRef<Slider | null>(null)
  const [reviews, setReviews] = useState<Review[] | null>(null)

  const getReviews = useCallback(async () => {
    try {
      const data = await fetchReviews(page)
      setReviews(data)
    } catch (error) {
      console.error("Ошибка при получении отзывов:", error)
    }
  }, [page, setReviews])

  useEffect(() => {
    getReviews()
  }, [getReviews])

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay()
    }
  }, [])

  const gotoNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext()
    }
  }

  const gotoPrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev()
    }
  }

  return (
    <section className={styles.wrapper} data-exclude={orderNum}>
      <Container>
        <div className={styles.title_container}>
          <h2 className={styles.title}>Отзывы</h2>
          <div className={styles.btn_groupe}>
            <IconButton className={styles.btn} onClick={() => gotoPrev()}>
              <ChevronLeftIcon className={styles.angle} />
            </IconButton>
            <IconButton className={styles.btn} onClick={() => gotoNext()}>
              <ChevronRightIcon className={styles.angle} />
            </IconButton>
          </div>
        </div>
      </Container>
      <Container
        className={clsx(styles.reviews_container, { [styles.reviews_container_null]: !reviews })}
      >
        <Slider {...settings} ref={sliderRef} className={styles.slider_wrapper}>
          {reviews && reviews.map((item) => <Card {...item} key={item.id} />)}
        </Slider>
      </Container>
    </section>
  )
}
