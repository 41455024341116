import sendRequest from "@ecom/ui/utils/sendRequest"

export const settings = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  autoplay: true,
  autoplaySpeed: 30000,
  rows: 2,
  arrows: false,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        rows: 3,
      },
    },
    {
      breakpoint: 960,
      settings: {
        variableWidth: true,
        slidesToShow: 1,
        slidesToScroll: 2,
        rows: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        variableWidth: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 1,
      },
    },
  ],
}

export type Review = {
  id: number
  logoCompany: string
  rate: number
  name: string
  review: string
  userImg: string
}
type Page = "default" | "debet"

export async function fetchReviews(category_name: Page) {
  try {
    const res: Review[] = await sendRequest(
      `${process.env.GATSBY_APP_HALVA_API_URL}/v2/halva/reviews?category_name=${category_name}`
    )
    return res
  } catch (error) {
    console.error("Fetch reviews failed", error)
    throw new Error("Fetch reviews failed")
  }
}
