// extracted by mini-css-extract-plugin
export var bannerShow = "banner-module--bannerShow--54XZf";
export var btn = "banner-module--btn--zgFdO";
export var btnBlock = "banner-module--btnBlock--QelMd";
export var container = "banner-module--container--euIzb";
export var darkColor = "banner-module--darkColor--fseil";
export var fixBottom = "banner-module--fixBottom--JzVGD";
export var hideMobBlock = "banner-module--hideMobBlock--A86Fy";
export var img = "banner-module--img--6RoC8";
export var mobBtn = "banner-module--mobBtn--TeohS";
export var red = "banner-module--red--GfejP";
export var secondContainer = "banner-module--secondContainer--WuaDq";
export var section = "banner-module--section--x7Paj";
export var text = "banner-module--text--cA-IB";