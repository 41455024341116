import React, { FC, useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import getSearchParam from "@ecom/ui/utils/getSearchParam"
import { GatsbyImageProps } from "gatsby-plugin-image"
import { BannerImage, BannerText, getDynamicBannerText } from "../helpers/getDynamicBannerText"
import { getDynamicBannerUrl } from "../helpers/getDynamicBannerUrl"
import { Banner as RoundedDynamicBanner } from "../components/Banners/DynamicBanners/RoundedDynamicBanner"
import { someFieldObjectIsNull } from "../helpers/someFieldObjectIsNull"

export const queryDynamicBanners = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
  }
`

interface BannerInfo {
  title?: string
  desc?: string
  orderNum?: string
}

interface DynamicBannerProps {
  title?: string
  description?: string
  orderNum?: string
  isDynamicText?: boolean
  isDynamicBanner?: boolean
}

interface StaticBannerProps {
  title?: string
  description?: string
  Img?: FC<Omit<GatsbyImageProps, "image">> | undefined
  variant?: "pkw" | "refin" | "refinMedia" | "pkwBonus" | undefined
  html?: string
  orderNum?: string | undefined
  isDynamicText?: boolean
  isDynamicBanner?: boolean
  buttonFloating?: boolean
  variantStyle?: string
}

export const useHybridBanner = <DataBanner,>(
  StaticBanner: React.FC<StaticBannerProps>,
  bannerInfo: BannerInfo,
  StaticBannerProps?: StaticBannerProps,
  DynamicBannerProps?: DynamicBannerProps,
  allDynamicBannerData?: DataBanner
): JSX.Element => {
  const { allDynamicBanner } = useStaticQuery(queryDynamicBanners)
  const bannerData = allDynamicBannerData || allDynamicBanner
  const [dynamicBannerTextObj, setDynamicBannerTextObj] = useState<BannerText>()
  const [dynamicBannerImagesObj, setDynamicBannerImagesObj] = useState<BannerImage | null>(null)
  const [banner, setBanner] = useState<JSX.Element>(
    <StaticBanner title="" description="" orderNum={bannerInfo.orderNum ?? "2"} />
  )

  const [bannerTextUrl, setBannerTextUrl] = useState(getSearchParam("h"))
  const [bannerImageUrl, setBannerImageUrl] = useState(getSearchParam("bn"))

  const removeQuery = () => {
    window.history.pushState(null, "", window.location.pathname)
    setBannerTextUrl("")
    setBannerImageUrl(null)
  }

  useEffect(() => {
    const fetchData = async () => {
      if (bannerTextUrl) {
        const bannerTextAdmin = await getDynamicBannerText(bannerTextUrl)
        if (!bannerTextAdmin || someFieldObjectIsNull(bannerTextAdmin)) {
          removeQuery()
        }
        setDynamicBannerTextObj(bannerTextAdmin)
      }
    }
    fetchData()
  }, [bannerTextUrl])

  useEffect(() => {
    if (bannerImageUrl) {
      const bannerImagesData = getDynamicBannerUrl(bannerData, bannerImageUrl)
      if (!bannerImagesData || someFieldObjectIsNull(bannerImagesData)) {
        removeQuery()
      }
      setDynamicBannerImagesObj(bannerImagesData as BannerImage | null)
    }
    document.body.style.display = "block"
  }, [bannerData, bannerImageUrl])

  // Костыль, нужен чтобы текст в баннере не прыгал
  useEffect(() => {
    if (dynamicBannerImagesObj && bannerImageUrl && (dynamicBannerTextObj || bannerTextUrl)) {
      setBanner(
        <RoundedDynamicBanner
          large={dynamicBannerImagesObj?.dymanicBannerDesk}
          mob={dynamicBannerImagesObj?.dymanicBannerMob}
          title={dynamicBannerTextObj?.title || ""}
          description={dynamicBannerTextObj?.description || ""}
          orderNum={bannerInfo.orderNum ?? "2"}
          isDynamicText
          {...DynamicBannerProps}
        />
      )
    } else if (dynamicBannerImagesObj && bannerImageUrl) {
      setBanner(
        <RoundedDynamicBanner
          large={dynamicBannerImagesObj?.dymanicBannerDesk}
          mob={dynamicBannerImagesObj?.dymanicBannerMob}
          title={bannerInfo.title}
          description={bannerInfo.desc}
          orderNum={bannerInfo.orderNum ?? "2"}
          {...DynamicBannerProps}
        />
      )
    } else if (dynamicBannerTextObj || bannerTextUrl) {
      setBanner(
        <StaticBanner
          title={dynamicBannerTextObj?.title || ""}
          description={dynamicBannerTextObj?.description || ""}
          orderNum={bannerInfo.orderNum ?? "2"}
          {...StaticBannerProps}
        />
      )
    } else {
      setBanner(
        <StaticBanner
          title={bannerInfo.title}
          description={bannerInfo.desc}
          orderNum="2"
          {...StaticBannerProps}
        />
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerImageUrl, bannerTextUrl, dynamicBannerImagesObj, dynamicBannerTextObj])

  return <>{banner}</>
}
