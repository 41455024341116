import { ImageDataLike } from "gatsby-plugin-image"

export type BannerText = {
  title: string
  description: string
}

export type BannerImage = {
  dymanicBannerMob: ImageDataLike
  dymanicBannerDesk: ImageDataLike
}

type ApiResponse = {
  data: {
    dynamicTitles: {
      items: {
        description: string
        name: string
        title: string
      }[]
    }
  }
}

export const getDynamicBannerText = async (string: string): Promise<BannerText | undefined> => {
  try {
    const resp = await fetch("https://api-app.sovcombank.ru/seo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
          query {
            dynamicTitles{
            items {
              description,
              name,
              title
            }
          }
        }
        `,
      }),
    })

    const { data } = (await resp.json()) as ApiResponse

    const bannerTextItem = data.dynamicTitles.items.find((item) => item.name === string)

    if (bannerTextItem) {
      const bannerText: BannerText = {
        title: bannerTextItem.title,
        description: bannerTextItem.description,
      }
      return bannerText
    }

    return undefined
  } catch (error) {
    console.error(error)
    return undefined
  }
}
